<template>
	<VueApexCharts type="pie" :height="height" :options="chartOptions" :series="series"></VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "GraficoPieDashboard",
	components: { VueApexCharts },
	props: ["dataSeries", "labels", "palette", "height", "legend"],
	data: () => ({
		series: [],
		chartOptions: {
			chart: {
				type: "pie",
			},
			legend: {
				position: "",
			},
			theme: {
				palette: "",
			},
			colors: ["#002D56", "#1764AA", "#45A5FC"],
			labels: [],
			responsive: [
				{
					breakpoint: 700,
					options: {
						legend: {
							show: false,
						},
						chart: {
							type: "pie",
							height: 320,
						},
					},
				},
			],
		},
	}),
	methods: {
		init() {
			this.series = this.dataSeries.map((v) => parseFloat(v.toFixed(2)));
			this.chartOptions.theme.palette = this.palette;
			this.chartOptions.labels = this.labels;
			this.chartOptions.legend.position = this.legend;
		},
	},
	created() {
		this.init();
	},
};
</script>
