<template>
	<VueApexCharts
		type="bar"
		:width="width ? width : '100%'"
		:height="height"
		:options="chartOptions"
		:series="series"
	></VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "GraficoBarDashboard",
	components: { VueApexCharts },
	props: [
		"dataSeries",
		"distributed",
		"categories",
		"horizontal",
		"palette",
		"money",
		"height",
		"width",
		"dataLabels",
		"porcentagem",
		"composto",
		"legenda",
		"labels",
		"color",
		"colorLabel",
		"grid",
		"mobile",
		"tooltip",
	],
	data: () => ({
		series: [],
		chartOptions: {
			responsive: [
				{
					breakpoint: 700,
					options: {
						yaxis: {
							show: false,
							forceNiceScale: true,
						},
						xaxis: {
							position: "bottom",
							labels: {
								trim: true,
							},
						},
						dataLabels: {
							enabled: false,
						},
					},
				},
			],
			chart: {
				type: "bar",
				height: 350,
				toolbar: {
					show: false,
				},
			},
			fill: {
				type: "gradient",
				gradient: {
					shadeIntensity: 0.75,
					opacityFrom: 0.9,
					opacityTo: 0.95,
					stops: [0, 10, 100],
				},
			},
			colors: ["#002D56", "#1764AA", "#45A5FC"],
			grid: {
				show: true,
			},
			theme: {
				palette: "",
			},
			legend: {
				show: false,
			},
			plotOptions: {
				bar: {
					borderRadius: 5,
					dataLabels: {},
					horizontal: "",
					columnWidth: "95%",
					distributed: true,
					endingShape: "rounded",
				},
			},
			dataLabels: {
				offsetY: 0,
			},
			stroke: {
				show: true,
				width: 3,
				colors: ["transparent"],
			},
			xaxis: {
				categories: "",
				labels: {
					show: true,
					rotate: -22,
					hideOverlappingLabels: false,
					trim: false,
				},
			},
			yaxis: {
				labels: {
					show: false,
				},
			},
			tooltip: {
				y: {},
			},
		},
	}),
	created() {
		this.series = this.dataSeries;
		this.chartOptions.plotOptions.bar.horizontal = this.horizontal;
		this.chartOptions.plotOptions.bar.distributed = this.distributed;
		this.chartOptions.plotOptions.bar.dataLabels = {
			position: this.dataLabels.position,
			orientation: this.dataLabels.orientation,
		};
		if (this.tooltip) {
			this.chartOptions.tooltip = this.tooltip;
		}
		this.chartOptions.theme.palette = this.palette;
		this.chartOptions.xaxis.categories = this.categories;
		if (this.labels) {
			this.chartOptions.xaxis.labels.rotate = 0;
		}
		if (this.mobile) {
			this.chartOptions.responsive[0] = this.mobile;
		}
		if (this.porcentagem) {
			this.chartOptions.tooltip.y = {
				enabled: true,
				formatter: function (val) {
					return `${parseFloat(val).toFixed(2)} %`;
				},
			};
			this.chartOptions.dataLabels = {
				enabled: true,
				formatter: function (val) {
					return `${parseFloat(val).toFixed(2)} %`;
				},
				style: {
					colors: ["#002D56"],
				},
				background: {
					enabled: true,
					borderColor: "#fff",
					opacity: 0.6,
				},
			};
		} else if (this.money) {
			this.chartOptions.tooltip.y = {
				enabled: true,
				formatter: function (val) {
					return parseFloat(val).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					});
				},
			};
			this.chartOptions.dataLabels = {
				enabled: true,
				formatter: function (val) {
					return parseFloat(val).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					});
				},
				style: {
					colors: ["#002D56"],
				},
				background: {
					enabled: true,
					borderColor: "#fff",
					opacity: 0.6,
				},
			};
		} else if (this.composto) {
			this.chartOptions.tooltip.y = {
				enabled: true,
				formatter: function (val, data) {
					return `${parseFloat(val).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})} - ${(
						(val /
							data.w.globals.series[0].reduce((a, v) => a + v)) *
						100
					).toFixed(2)}%`;
				},
			};
			this.chartOptions.dataLabels = {
				enabled: true,
				formatter: function (val, data) {
					return `${(
						(val /
							data.w.globals.series[0].reduce((a, v) => a + v)) *
						100
					).toFixed(2)}%`;
				},
				style: {
					colors: ["#002D56"],
				},
				background: {
					enabled: true,
					borderColor: "#fff",
					opacity: 0.6,
				},
			};
		} else if (this.legenda) {
			this.chartOptions.tooltip.y = {
				enabled: true,
				formatter: function (val) {
					return `${val}`;
				},
			};
			this.chartOptions.dataLabels = {
				enabled: true,
				formatter: function (val, data) {
					return `${
						data.w.globals.labels[data.dataPointIndex]
					}: ${val}`;
				},
				style: {
					colors: ["#002D56"],
				},
				background: {
					enabled: true,
					borderColor: "#fff",
					opacity: 0.6,
				},
			};
		} else {
			this.chartOptions.tooltip.y = {
				enabled: true,
			};
			this.chartOptions.dataLabels = {
				enabled: true,
				style: {
					colors: ["#002D56"],
				},
				background: {
					enabled: true,
					borderColor: "#fff",
					opacity: 0.6,
				},
			};
		}
		if (this.dataLabels.offsetY) {
			this.chartOptions.dataLabels.offsetY = this.dataLabels.offsetY;
		}
		if (this.grid == "clean") {
			this.chartOptions.grid.show = false;
		}
		if (this.color) {
			this.chartOptions.colors = this.color;
		}
		if (this.colorLabel) {
			this.chartOptions.dataLabels.style.colors = this.colorLabel;
		}
	},
};
</script>

<style>
.apexcharts-tooltip {
	background: #ffffff !important;
	color: #023449;
	border-color: #023449 !important;
}

.apexcharts-tooltip-title {
	background-color: #023449 !important;
	color: #ffffff;
}
</style>
